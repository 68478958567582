// color variants
@import 'themes-vars.module.scss';
@import 'custom-fonts.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// @function calculateTypeOffset($lh, $fontSize, $descenderHeightScale) {
//     $lineHeightScale: $lh / $fontSize;
//     @return ($lineHeightScale - 1) / 2 + $descenderHeightScale;
// }

// @mixin basekick($typeSizeModifier, $baseFontSize, $descenderHeightScale, $typeRowSpan, $gridRowHeight, $capHeight) {
//     $fontSize: $typeSizeModifier * $baseFontSize;
//     $lineHeight: $typeRowSpan * $gridRowHeight;
//     $typeOffset: calculateTypeOffset($lineHeight, $fontSize, $descenderHeightScale);
//     $topSpace: $lineHeight - $capHeight * $fontSize;
//     $heightCorrection: 0;

//     @if $topSpace > $gridRowHeight {
//         $heightCorrection: $topSpace - ($topSpace % $gridRowHeight);
//     }

//     $preventCollapse: 1;

//     font-size: #{$fontSize}px;
//     line-height: #{$lineHeight}px;
//     transform: translateY(#{$typeOffset}em);
//     padding-top: $preventCollapse;

//     &::before {
//         content: '';
//         margin-top: #{- ($heightCorrection + $preventCollapse)}px;
//         display: block;
//         height: 0;
//     }
// }

* {
    /* Foreground, Background */
    scrollbar-color: $paper $primaryMain;
}
*::-webkit-scrollbar {
    width: 5px; /* Mostly for vertical scrollbars */
    height: 5px; /* Mostly for horizontal scrollbars */
}

*::-webkit-scrollbar-thumb {
    /* Foreground */
    background: $paper;
}
*::-webkit-scrollbar-track {
    /* Background */
    background: $primaryMain;
}

body {
    // background: $primaryMain;
    background: $paper;
}

p {
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
    // margin-inline-start: 0px;
    // margin-inline-end: 0px;
}

.formTextarea {
    width: 100% !important;
    height: 20rem !important;

    .MuiInput-root {
        // min-width: 100% !important;
        // width: 100% !important;

        // bottom: 0 !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: flex-start !important;
        // width: 100% !important;
        font-size: 1.2rem;
        height: 20rem !important;
        width: calc(100% - 1rem) !important;
        padding: 1.1rem 0 1.1rem 5rem !important;

        & > ::placeholder {
            color: $primaryMain;
            font-size: 1.2rem;
            opacity: 1;
            text-align: right !important;
        }

        > * {
            overflow-y: scroll !important;

            color: $primaryMain;
            // margin: 0 !important;
            width: 100% !important;
            height: 20rem !important;
            padding-right: 2rem !important;
        }
    }
}
.formInput {
    width: 100% !important;
    height: 3.75rem !important;

    .MuiInput-root {
        // min-width: 100% !important;
        // width: 100% !important;
        margin: 0 0 !important;

        // bottom: 0 !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: stretch !important;
        // width: 100% !important;
        font-size: 1.2rem;
        height: 3.75rem !important;
        width: 100% !important;
        padding: 0 2rem 0 4rem !important;

        & > ::placeholder {
            color: $primaryMain;
            font-size: 1.2rem;
            opacity: 1;
            text-align: right !important;
        }

        > * {
            color: $primaryMain;

            margin: 0 !important;
            height: 3.75rem !important;
        }
    }
}
.simpleFormInput {
    width: 100% !important;
    height: 3.75rem !important;

    .MuiFormHelperText-root {
        color: $secondaryDark !important;
    }
    .MuiInput-root {
        // min-width: 100% !important;
        // width: 100% !important;
        margin: 0 0 !important;

        // bottom: 0 !important;
        display: flex !important;
        // flex-direction: column !important;
        justify-content: stretch !important;
        // width: 100% !important;
        font-size: 1.2rem;
        height: 3.75rem !important;
        width: 100% !important;
        padding: 0.5rem 0 0 0 !important;

        & > ::placeholder {
            color: $grey500;
            font-size: 1.2rem;
            opacity: 1;
            // text-align: right !important;
        }

        > * {
            color: $grey700;

            margin: 0 !important;
            height: 3.75rem !important;
            &:autofill {
                height: 2rem !important;
            }

            &:-webkit-autofill {
                height: 2.6rem !important;
            }
        }
    }
}

.simpleFormTextarea {
    width: 100% !important;
    height: 20rem !important;

    .MuiInput-root {
        // min-width: 100% !important;
        // width: 100% !important;

        // bottom: 0 !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: flex-start !important;
        // width: 100% !important;
        font-size: 1.2rem;
        height: 20rem !important;
        width: calc(100%) !important;
        padding: 0.5rem 0 0 2rem !important;

        & > ::placeholder {
            color: $grey500;
            font-size: 1.2rem;
            opacity: 1;
            // text-align: right !important;
        }

        > * {
            overflow-y: scroll !important;

            color: $grey700;
            // margin: 0 !important;
            width: 100% !important;
            height: 20rem !important;
            padding-right: 2rem !important;
        }
    }
}
.MuiInputLabel-root.MuiInputLabel-formControl.Mui-error {
    color: $secondaryDark !important;
}
.MuiInputBase-root.Mui-error:after {
    border-bottom-color: $secondary200 !important;
}
.MuiInputBase-root:before {
    border-bottom: none !important;
}
.MuiFormHelperText-root {
    color: $primaryLight !important;
}

#iubenda-pp {
    // top: 72px !important;
    // position: fixed !important;
    z-index: 999999999999999999999999999999999 !important;
    // overflow-y: hidden;
    // #iubenda-pp-popup {
    //     // overflow-y: hidden;

    //     #iubenda-pp-content {
    //         height: 60vh !important;
    //         // overflow-y: hidden;
    //     }
    // }
}
// iframe > #iubenda-policy #wbars {
//     height: 60vh !important;
// }
.timer-pager.swiper-pagination-bullet {
    display: flex;
    z-index: 5 !important;
    // background: 'red',
    background: $paper;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 0.25rem;
    opacity: 0.4;
    padding: 0;
    margin: 0 !important;

    &.swiper-pagination-bullet-active {
        background: $paper;
        opacity: 1;
    }
    &:not(:last-child) {
        margin-right: 6px !important;
    }
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}
// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

.fullDeskQuote {
    font-size: 4rem !important;
    * {
        font-size: 4rem !important;
    }
}
.fullMobQuote {
    font-size: 1.25rem !important;
    line-height: 1.875rem !important;
    * {
        font-size: 1.25rem !important;
        line-height: 1.875rem !important;
    }
}
.articleBody {
    a,
    a:visited,
    a:active {
        color: $primaryMain !important;
        font-weight: 500;

        // font-weight: 600;
        // font-size: 1.5rem;
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
}
.articleBodyV2 {
    a,
    a:visited,
    a:active {
        color: $primaryMain !important;
        // font-weight: 600;

        font-weight: 600;
        font-size: 1.5rem;
        text-decoration: none;
        text-transform: uppercase;
    }
    a:hover {
        text-decoration: underline;
    }
    // > p {
    //     :first-child {
    //         :first-letter {
    //             font-size: 4.5rem;
    //             font-weight: 400;
    //             float: left;
    //             color: $primaryMain;
    //             line-height: 4.5rem;
    //             margin-right: 0.75rem;
    //         }
    //     }
    // }
}

.customIndicator {
    background: 'red';
}

.iubenda-embed {
    text-decoration: none !important;
}
ul.MuiList-root.MuiMenu-list {
    max-height: 300px !important;
    overflow-y: auto !important;
}
.MuiMenu-paper {
    max-height: 300px !important;
    overflow-y: auto !important;
    border-radius: 0 !important;
    background: rgba(255, 255, 255, 0.75) !important;
    backdrop-filter: blur(10px) !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

ul.MuiList-root.MuiMenu-list[aria-labelledby='frontendSelect'] {
    max-height: 200px !important;
    overflow-y: auto !important;
    // background: rgba(0, 0, 0, 0) !important;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}
