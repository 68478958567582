@font-face {
    font-family: 'outward';
    src: url('../fonts/outward-block-webfont.eot');
    src: url('../fonts/outward-block-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/outward-block-webfont.woff2') format('woff2'), url('../fonts/outward-block-webfont.woff') format('woff'),
        url('../fonts/outward-block-webfont.ttf') format('truetype'), url('../fonts/outward-block-webfont.svg#outwardblock') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'outward-borders';
    src: url('../fonts/outward-borders-webfont.eot');
    src: url('../fonts/outward-borders-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/outward-borders-webfont.woff2') format('woff2'), url('../fonts/outward-borders-webfont.woff') format('woff'),
        url('../fonts/outward-borders-webfont.ttf') format('truetype'),
        url('../fonts/outward-borders-webfont.svg#outwardborders') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SkySansW01';
    src: url('../fonts/SkySansW01-Black.eot');
    src: url('../fonts/SkySansW01-Black.eot?#iefix') format('embedded-opentype'), url('../fonts/SkySansW01-Black.woff2') format('woff2'),
        url('../fonts/SkySansW01-Black.woff') format('woff'), url('../fonts/SkySansW01-Black.ttf') format('truetype'),
        url('../fonts/SkySansW01-Black.svg#SkySansW01-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SkySansW01';
    src: url('../fonts/SkySansW01-Book.eot');
    src: url('../fonts/SkySansW01-Book.eot?#iefix') format('embedded-opentype'), url('../fonts/SkySansW01-Book.woff2') format('woff2'),
        url('../fonts/SkySansW01-Book.woff') format('woff'), url('../fonts/SkySansW01-Book.ttf') format('truetype'),
        url('../fonts/SkySansW01-Book.svg#SkySansW01-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SkySansW01';
    src: url('../fonts/SkySansW01-Bold.eot');
    src: url('../fonts/SkySansW01-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/SkySansW01-Bold.woff2') format('woff2'),
        url('../fonts/SkySansW01-Bold.woff') format('woff'), url('../fonts/SkySansW01-Bold.ttf') format('truetype'),
        url('../fonts/SkySansW01-Bold.svg#SkySansW01-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SkySansW01';
    src: url('../fonts/SkySansW01-Light.eot');
    src: url('../fonts/SkySansW01-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/SkySansW01-Light.woff2') format('woff2'),
        url('../fonts/SkySansW01-Light.woff') format('woff'), url('../fonts/SkySansW01-Light.ttf') format('truetype'),
        url('../fonts/SkySansW01-Light.svg#SkySansW01-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SkySansW01';
    src: url('../fonts/SkySansW01-Semibold.eot');
    src: url('../fonts/SkySansW01-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SkySansW01-Semibold.woff2') format('woff2'), url('../fonts/SkySansW01-Semibold.woff') format('woff'),
        url('../fonts/SkySansW01-Semibold.ttf') format('truetype'),
        url('../fonts/SkySansW01-Semibold.svg#SkySansW01-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SkySansW01';
    src: url('../fonts/SkySansW01-Medium.eot');
    src: url('../fonts/SkySansW01-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/SkySansW01-Medium.woff2') format('woff2'),
        url('../fonts/SkySansW01-Medium.woff') format('woff'), url('../fonts/SkySansW01-Medium.ttf') format('truetype'),
        url('../fonts/SkySansW01-Medium.svg#SkySansW01-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
